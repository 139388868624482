import React from "react"
import Section from "../../Elements/Section"
import Message from "../../Elements/Message"
import EditDetailsButton from "../../Elements/EditDetailsButton"
import MedicineInfoRow from "./MedicineInfoRow"
import { accumulatePrice, formatPrice } from "../services/computations"
import { DELIVERY_FEE } from "../services/constants"

const MedicinesOrderedSummary = ({ route, medicines, epharmacyState }) => {
  const orderTotal = formatPrice({
    priceString: (accumulatePrice(medicines) + DELIVERY_FEE).toFixed(2),
  })

  const paymentOption = epharmacyState?.paymentOption

  return (
    <Section
      title={`Medicines Requested`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
      isSectionRequired={true}
      className="is-size-6"
    >
      {medicines?.length > 0 ? (
        <div className="mx-1">
          <div className="columns is-vcentered is-multiline">
            {medicines?.map((medicine) => (
              <div className="column is-12">
                <MedicineInfoRow medicine={medicine} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            You have not added any medicines.
          </p>
        </Message>
      )}
      <div className="pt-1 px-1 mb-3">
        <div className="columns is-mobile mb-0">
          <div className="column has-text-weight-bold">Convenience Fee</div>
          <div className="column has-text-right">{DELIVERY_FEE.toFixed(2)}</div>
        </div>
        <div className="columns is-mobile">
          <div className="column has-text-grey is-size-5">
            Order Total:{" "}
            <span className="has-text-black has-text-left is-size-5 has-text-weight-bold">
              P {orderTotal}
            </span>
          </div>
          <div className="column has-text-grey is-size-5">
            MOP:{" "}
            <span className="has-text-black has-text-left is-size-5 has-text-weight-bold">
              {paymentOption}
            </span>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default MedicinesOrderedSummary
