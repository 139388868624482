import { accumulatePrice, formatPrice } from "../../services/computations"
import { DELIVERY_FEE } from "../../services/constants"

export const orderRequestZendeskTemplate = (request) => {
  const {
    // personal details
    firstName,
    middleName,
    lastName,
    email,
    mobileNumber,
    deliveryAddress,
    authorizedPerson,
    authorizedPersonMobileNumber,
    // medicines
    medicines,
    // payment options
    optionalNotes,
    paymentOption,
    preferredDeliveryDate,
    fullName,
    hospitalName,
    otherHospital,
    clinicAddress,
    changeFor,
    preferredBank,
    prescriptionDate,
  } = request

  const {
    addressType,
    barangay,
    city,
    province,
    streetAddress,
  } = deliveryAddress

  let paymentMethod = `${paymentOption}${
    paymentOption !== "Cash on Delivery" && !!preferredBank
      ? ` - ${preferredBank}`
      : ""
  }`

  const parsedHospitalName =
    hospitalName?.value === "Other" ? otherHospital : hospitalName?.value

  const parsedDeliveryFee = formatPrice({
    priceString: DELIVERY_FEE?.toFixed(2),
  })

  const orderTotal = formatPrice({
    priceString: (accumulatePrice(medicines) + DELIVERY_FEE).toFixed(2),
  })

  const parsedChangeFor =
    paymentOption === "Cash on Delivery" && !!changeFor
      ? formatPrice({
          priceString: changeFor?.toFixed(2),
        })
      : "N/A"

  let personalDetailsBody = `First Name: ${firstName}
  Middle Name: ${middleName}
  Last Name: ${lastName}
  Email: ${email}
  Contact Number: ${mobileNumber}
  Type of ID Uploaded: N/A
  Authorized Person to Receive: ${authorizedPerson || "N/A"}
  Number of Authorized Person: ${authorizedPersonMobileNumber || "N/A"}
  Address Type: ${addressType}
  Street Address: ${streetAddress}
  Barangay: ${barangay?.value}
  City: ${city?.value}
  Province: ${province?.value}`

  let medicinesBody = parseMedicines(medicines)

  let paymentOptionsBody = `Order Notes: ${optionalNotes || "N/A"}
  Payment Method: ${paymentMethod}
  Preferred Delivery Date: ${printDateValue(preferredDeliveryDate)}
  Prescribing Doctor: ${fullName}
  Hospital/Clinic Name: ${parsedHospitalName}
  Hospital/Clinic Address: ${clinicAddress?.city?.value}, ${
    clinicAddress?.province?.value
  }`

  let totalBody = `Convenience Fee: ${parsedDeliveryFee}
  Total: ${orderTotal}
  Change for: ${parsedChangeFor}`

  let ticketBody = `${personalDetailsBody}
  ${paymentOptionsBody}
  ${medicinesBody}
  Prescription Date: ${printDateValue(prescriptionDate)}
  ${totalBody}
  ---
  I agree that my order will not be served if the prescription provided indicates cutting tablets in half (e.g. Sacubitril-Valsartan 200mg tablet, 1/2 tablet twice a day) and I will visit my doctor to get an updated prescription for whole tablet intake.`

  return ticketBody
}

const printDateValue = (date) => {
  return `${date?.month?.label} ${
    date?.date?.label ? `${date?.date?.label}, ` : ""
  }${date?.year?.label ? `${date?.year?.label}` : ""}`
}

const parseMedicines = (medicines) => {
  let medicinesBody = `Medicines:`
  medicines.forEach((medicine) => {
    let assistanceMeds = ""
    let price = medicine?.price_list?.unitPrice.toFixed(2)
    if (medicine.assistanceQuantity > 0) {
      assistanceMeds = medicine?.productTitle?.replace(
        ")",
        " - Medicine Assistance)"
      )
      medicinesBody += `\n${medicine?.productTitle} #${medicine?.qty}, Price: ₱${price}`
      medicinesBody += `\n${assistanceMeds} #${medicine?.assistanceQuantity}, Price: ₱ 0.00`
    } else {
      medicinesBody += `\n${medicine?.productTitle} #${medicine?.qty}, Price: ₱${price}`
    }
  })

  return medicinesBody
}
